.header{
    display: flex;
    flex: 1;
    padding:0;
    background:var(--flag-blue);
    background: -webkit-gradient(linear,left top,left bottom,from(#002768),to(#002768));
    background: linear-gradient(-180deg,#002768,#002768);
    color:white;
    justify-content: center;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1201;
    height: 70px;
}

.header__container{
    display: flex;
    flex:.65;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.header__left{
    display: flex;
    flex:0.3;
    align-items: center;
    margin-left:20px;
}

.header__left > .MuiSvgIcon-root {
    margin-left: auto;
    margin-right: 30px;
}

.header__search{
    flex:0.4;
    background-color: #002768;
    display: flex;
    text-align: center;
    color:rgb(243, 197, 197);
    /* border:1px solid rgb(243, 197, 197);
    border-radius: 6px; */
}

.header__search > input{
    background-color: transparent;
    border:none;
    text-align:center;
    min-width: 35vw;
    outline:none;
    color:rgb(243, 197, 197);
}

.header__search > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
  }

.header__right{
    flex:0.3;
    display: flex;
    align-items: flex-end;
}

.header__right > .MuiSvgIcon-root{
    margin:auto;
    margin-right: 20px;
}

.header__siteName{
    text-decoration: none;
    color: white;
}

h2.header__siteName{
margin: 0;
}

a{
    text-decoration: none;
}
.header__avatar{
    margin-right: 20px;
}

.header__menuProfile > .MuiPaper-root{
    margin-top: 40px;
}