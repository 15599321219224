.verify__email{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.verify__email > .MuiPaper-root{
    flex: .5;
    height: 50vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--flag-blue);
    color:white;
}

.verify__email > .MuiPaper-root > .MuiSvgIcon-root{
    font-size: 10em !important;
    margin-bottom: 20px;
    color: greenyellow;
}

h1,h2{
    margin-bottom: 20px;
}