.sidebarOption{
    display: flex;
    align-items: center;
    font-size:12px;
    padding-left: 2px;
    cursor:pointer;
}

.sidebarOption:hover {
    opacity: .9;
    background-color: var(--flag-blue);
}

.sidebarOption.active {
    opacity: .9;
    background-color: var(--flag-blue);
}

.sidebarOption__icon {
    padding:10px;
    font-size: 20px !important;
}

.sidebarOption__channel {
    padding:10px 0;
}

.sidebarOption__hash{
    padding:10px;
}

.sidebarOption > h3 {
    font-weight: 500;
    font-size: 16px; 
}