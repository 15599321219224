.commentSection{
    padding: 0px 10px;
}

.commentSection__Form{
    display: flex;
    flex:1;
    align-items: center;
}

.commentSection__Form > .MuiAvatar-root{
    display: flex;
    margin-right: 20px;
    justify-self: center;
}

.commentSection__FormTextArea{
    display: flex;
    flex:1;
    padding-bottom: 10px;
}

.commentSection__FormTextArea > .MuiFormControl-root{
    flex: 1;
}

.commentSection__FormButton{
    display: flex;
    flex:.2;
    align-content: center;
}

.commentSection__FormButton > .MuiButton-containedPrimary {
    background-color: var(--flag-blue);
    flex: 1;
    height: 50px;
    padding: 10px;
    margin:10px
}

.commentSection__Comments{
    margin-bottom: 20px;
}

.commentSection__Comments > .MuiPaper-root > .MuiCardHeader-root{
    background-color: var(--flag-blue);
    color:white;
}

.commentSection__Comments > .MuiPaper-root > .MuiCardHeader-root .MuiTypography-h5{
    font-size: 12pt;
    font-weight: bold;
}

.commentSection__Comments > .MuiPaper-root > .MuiCardContent-root{
    min-height: 100px;
}

.noLogin{
    text-align: center;
}

.hideButton{
    display: none;
}

.memeUpload__imagePreview {
    width:250px;
    margin-left: 60px;
}