.memeContainer{
        border: none;
        color: #ffffff;
        padding: 9px 0;
        margin: 10px 0px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: var(--flag-blue);
        border-radius: 6px;
        font-weight: bold;
}

.memeContainer__title{
    text-align: center;
    margin-bottom: 10px;
    font-size:18px;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.memeContainer__title > p{
    flex:1;
}

.memeContainer__title > .MuiSvgIcon-root{
    justify-self: flex-end;
}

.memeContainer__info{
    display: flex;
    align-items: center;
    background-color: var(--flag-red);
}

.memeContainer__userInfo , .memeContainer__timestamp{
    display: flex;
    flex: 0.5;
    padding: 5px;
    font-weight: 500;
}

.memeContainer__timestamp{
    flex-direction: row-reverse;
}

.memeContainer__meme > img{
    display: flex;
    flex:1;
    object-fit: contain;
    width:100%;
}

.memeContainer__controls{
    display: flex;
}

.memeContainer__controls > .MuiSvgIcon-root {
    flex:0.20;
    padding: 5px;
    padding-top:15px;
}
.likePercentage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex:.2;
}
.likeness{
    font-size: 18px;
    color:greenyellow;
    padding: 2px;
}
.likesCount{
    font-size:14px;
}

.MuiSvgIcon-root.likeSelected{
    color: #3f51b5;
}

.memeContainer__desc{
    background-color: white;
    color: #000000;
    padding:10px 20px;
    font-weight: normal;
}

.memeContainer__controls > a{
    flex:0.20;
    padding: 5px;
    padding-top:15px;
    text-align: center;
}

.memeContainer__controls > a > .MuiSvgIcon-root{
    
    color:white;
}