.login{
    
    background-color: var(--flag-blue);
    display: grid;
    place-items: center;
}

.login__container{
    padding:100px;
    text-align: center;
    background-color: var(--flag-blue);
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color:#fff;
}

.login__container > img {
    object-fit: contain;
    height: 100px;
    margin-bottom: 40px;
}

.login__container > button,.login__containerUser > button,.login__containerUser > form > button {
    margin-top: 50px;
    text-transform: inherit !important;
    background-color: var(--flag-red) !important;
    color:white;
    min-width: 80%;
}
.login__containerUser > form{
    margin-top:20px;
}

.login__containerUser > form > input {
    margin-top:5px;
    margin-bottom: 5px;
    height:30px;
    background-color: white;
    width:98%;
    object-fit: contain;
    border:1px solid lightgray;
    border-radius: 10px;
    text-align:center;
}


