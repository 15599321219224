.sidebar{
    color:white;
    background: #bf0b30;
    background: -webkit-gradient(linear,left top,left bottom,from(#bf0b30),to(#bf0b30));
    background: linear-gradient(-180deg,#bf0b30,#bf0b30);
    border-top:1px solid #fa5d7d;
    flex: 0.2;
    height: calc(100vh - 71px);
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 70px;
    align-self: flex-start; /* <-- this is the fix */
    z-index: 1200;
    margin-right: 6px;
}

.sidebar__info{
    flex:1;
}

.sidebar__header{
    display: flex;
    border-bottom: 1px solid #bf0b30;
    padding-bottom: 10px;
    padding: 13px;
}

.sidebar__info > h2 {
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 5px;
}

.sidebar__info > h3 {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;

}

.sidebar__info > h3 > .MuiSvgIcon-root {
    color: green;
    font-size: 14px;
    margin-top:1px;
    margin-right:2px;
}

.sidebar__header > .MuiSvgIcon-root {
    padding:8px;
    color: #bf0b30;
    font-size: 18px;
    background-color: white;
    border-radius:999px;
}

.sidebar__channels > hr{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ffffff;
}

.sidebar__uploadButton{
    width: 100% !important;
    background-color: white !important;
    color: #bf0b30 !important;
    padding:10px !important;
    font-weight: bold !important;
}

.sidebar__uploadButton > .MuiButton-label > .MuiSvgIcon-root {
    margin-left: 20px !important;
}

.sidebar__copyright{
    margin-left: 20px;
}

a,a:hover{
    color: white;
}
