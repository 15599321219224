.commentContainer{
    display: flex;
    flex:1;
    flex-wrap: wrap;
    margin-bottom: 20px;
}


.commentContainer > .commentContainer__avatar{
    flex-basis:10%;
}

.commentContainer__details{
    flex-basis:90%;
}
.commentContainer__reply{
    flex-basis:100%;
}

.commentContainer__commentImage > img{
    width: 250px;
    padding:5px;
}

.commentContainer__replyDetails{
    margin-top: 20px;
}