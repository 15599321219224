body {
  --meme-color: #d32f21;
  --flag-blue: #002768;
  --flag-red: #bf0b30;
}
.app {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.app__body {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #eee9e9;
  height: 100%;
}

.app__container {
  display: flex;
  flex: 0.65;
  justify-content: center;
  flex-wrap: wrap;
}

@media all and (max-width: 959px) {
  .sidebar {
    display: none;
  }
  .rightSidebar {
    display: none !important;
  }
  .memes,
  .app__container,
  .header__container {
    flex: 1 !important;
  }
  .header__left {
    flex: 0.8 !important;
  }
  .header__search {
    display: none !important;
  }
  .memeContainer,
  .memes__filterInfo {
    border-radius: 0px !important;
  }
  .commentSection {
    padding: 0px !important;
  }

  .commentContainer__details {
    margin-left: 20px !important;
  }
  .login__container {
    padding: 50px 10px !important;
  }
  .adsContainer{
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
