.account__profile{
    flex: .8;
    display: flex;
    height: calc(100vh - 70px);
}

.account__profileCard{
    display: flex;
    flex: 1;
    margin: 10px;
    height: calc(100% - 20px);
}

.account__profileCard > .MuiCardContent-root{
    display: flex;
    flex:1; 
    flex-direction: column;
}

.MuiAvatar-root.account__profileavatar{
    width: 150px;
    height: 150px;
    margin:20px;
}
.MuiButton-root.account__profileAvatarChange{
    background-color: var(--flag-blue);
    color: white;
    font-size: 8pt;
    margin:0 20px;
}

.MuiButton-root.account__profileAvatarChange:hover{
    background-color: var(--flag-red);
    color: white;
    font-size: 8pt;
    margin:0 20px;
}

.account__profileDetails{
    margin: 20px;
}

.account__profileDetails > h2{
    margin: 0;
}

.normalFont{
    font-weight: normal;
}

.account__stats{
    margin-top: 20px;
}

.account__statsGrid{
    display: flex;
    flex:1;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 20px;
}


.account__statsGrid > .MuiCard-root{
    display: flex;
    flex: .25 1 20%;
    background-color: var(--flag-blue);
    margin: 15px;
    min-height: 150px;
}

.imageUploadPreviewProfile {
    width: 98%;
}