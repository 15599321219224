.rightSidebar{
    display: flex;
    flex: 0.3;
    flex-direction: column;
}

.rightSidebar__FBPage{
    margin-top:10px;
}

.rightSidebar__ads{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.rightSidebar__ads > iframe {
    margin:20px 10px;
    border: none;
}