.memes{
    display: flex;
    flex:0.5;
    flex-direction: column;
}

.memes__filterInfo{
    border: none;
    color: #ffffff;
    padding: 9px 12px;
    margin: 10px 0px;
    margin-bottom: 5px;
    overflow: hidden;
    background-color: var(--flag-red);
    border-radius: 6px;
    font-weight: bold;
}

.memes__bannerImage{
    padding:9px 0px;
}

.memes__bannerImage > img {
    width: 100%;
}

.memes > .MuiPagination-root{
    text-align: center;
    display: flex;
    align-self: center;
    margin:10px 0px;
}

.adsContainer{
    display: none;
}

.memes__newSocialImage{
    background-color: white;
}

.memes__newSocialImage img{
    width: 50%;
    vertical-align: middle;
}