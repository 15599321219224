.memeUpload__hidden{
    display: none;
}

.memeUpload__imagePreview{
    max-width: 100%;
}

.MuiDialogTitle-root{
    background-color: var(--flag-blue) !important;
    color:white !important;
}

.memeTitle,.memeDesc{
    padding: 10px !important;
    margin-bottom: 40px !important;
    width:96%;
  
}
.imageUploadPreview{
    width: 98%;
    min-height: 300px;
    margin-bottom: 20px;
}

